import React from "react";
import { routes } from "./allRoutes";
import { Route, Routes } from "react-router-dom";

const CommonLayout = React.lazy(() => import("../layout"));

const Index = () => {
  return (
    <>
      <Routes>
        <Route>
          {routes.map((route, idx) => (
            <Route
              path={route.path}
              element={<CommonLayout>{route.component}</CommonLayout>}
              key={idx}
              exact={true}
            />
          ))}
        </Route>
      </Routes>
    </>
  );
};

export default Index;
