import { lazy } from "react";

const Home = lazy(() => import("../pages/home"));
const Trainers = lazy(() => import("../pages/trainers"));

const routes = [
  { path: "/", component: <Home /> },
  { path: "/trainers", component: <Trainers /> },
];

export { routes };
