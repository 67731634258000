import React from "react";
import Routes from "./Routes";

//import Custom Style scss
import "./assets/scss/themes.scss";
function App() {
  return <Routes />;
}

export default App;
